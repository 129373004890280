::-webkit-scrollbar {
    width: 0
}

.ce-chat-engine {
    position:fixed !important;
    overflow-y: hidden !important;
    width:100vw !important;
    height:calc(100svh - 64px) !important;
}

.ce-chat-feed-column {
    border:1px solid;
    border-color:#D9D9D9;
}

.ce-chat-feed-column, .ce-chat-feed {
    flex: 0 0 auto !important;
    overflow:hidden !important;
}

.ce-message-date-text {
    display:none
}

.ce-avatar {
    color:transparent !important;
}

@media (max-width:575px) {
    .ce-chat-engine {
        left:0% !important;
    }

    .ce-chat-feed-column, .ce-chat-feed {
        min-width:75vw !important;
        max-width:100vw !important;
    }

    .ce-chat-list-column, .ce-chat-list {
        position:absolute !important;
        left:0 !important;
        width:100% !important;
    }

}

@media (min-width:575px) {
    .ce-chat-list-column, .ce-chat-list {
        position:fixed !important;
        right:0;
        width:25% !important;
    }

    .ce-chat-feed-column, .ce-chat-feed {
        width:50vw !important;
    }

    .ce-chat-engine {
        left:25% !important;
    }
}

.ce-chat-list-column, .ce-chat-settings-column,.ce-chat-feed-column, .ce-chat-feed {
    height:calc(100svh - 64px) !important;
    overflow-y: hidden !important;
}

.ce-chat-settings-column {
    display:none !important;
}

.ce-message-list {
    height:calc(100% - 225px) !important;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:10px;
}

#ce-message-form {
    height:140px;
    border-top:2px solid;
    border-color:#E0E0E0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding-right:10px;
    padding-left:10px;
}

#msg-textarea {
    padding-top:10px;
    margin-right:10px;
    margin-bottom:15px;
    height:80px !important;
    border:1px solid !important;
    border-color:#D9D9D9 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: large !important;
    width:100% !important;
}

.ce-attachment-input {
    display:none !important;
}

#ce-send-message-button {
    border-radius:5px !important;
    background-color: #656EFF !important;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.ce-chat-header {
    border-bottom: 2px solid;
    border-color:#D9D9D9 !important;
}

.ce-my-message-body, 
.ce-their-message-body, 
.ce-their-message-sender-username, 
.ce-my-message-timestamp, 
.ce-their-message-timestamp, 
.ce-message-date-text,
.ce-chat-header-subtitle,
.ce-chat-header-title,
.ce-avatar {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: medium !important;
}

.ce-mobile-chat-settings-button {
    display:none !important
}

.ce-mobile-chat-list-button {
    top:18px !important;
}