.announcement_channel .sendbird-openchannel-conversation-scroll {
    max-height:350px !important;
}

.announcement_channel .sendbird-openchannel-footer, .announcement_channel .sendbird-openchannel-conversation-scroll {
    background-color: #F8F8F8 !important;
}

.announcement_channel .sendbird-openchannel-user-message__left {
    display:none !important;
}

.announcement_channel .sendbird-openchannel-user-message__right {
    padding-right:15px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-left: 25px !important;
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    margin-right: 20px !important;
}

.announcement_channel .sendbird-openchannel-user-message__right__bottom__message {
    color:#000000CC !important;
    font-family: 'Roboto' !important;
}

.announcement_channel .sendbird-message-input-text-field {
    border-radius: 32px !important;
    border: 0px !important;
    padding-left:10px !important;
}

.announcement_channel .sendbird-message-input .sendbird-message-input--send {
    position:absolute !important;
    right:0px !important;
}

.announcement_channel .sendbird-message-input .sendbird-message-input--placeholder {
    padding-left: 20px !important;
}

.announcement_channel .sendbird-message-input .sendbird-message-input--textarea {
    padding-right:30px !important;
    padding-left: 20px !important;
    padding-top: 18px !important;
}

.sendbird-openchannel-user-message .sendbird-openchannel-user-message__right .sendbird-openchannel-user-message__right__bottom .sendbird-openchannel-user-message__right__bottom__message {
    word-break: normal !important;
}

.announcement_channel .sendbird-openchannel-footer .authenticated {
    padding-bottom: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    max-width: '100%' !important;
}

.announcement_channel .sendbird-openchannel-user-message__context-menu {
    display:flex !important;
    position:absolute !important;
    right:0px !important;
}

.announcement_channel .sendbird-iconbutton {
    background-color: transparent !important;
}

.announcement_channel .sendbird-openchannel-user-message__right__top__sent-at {
    padding-left:5px !important;
}

.announcement_channel .unauthenticated {
    display: none !important;
}

.sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
    right:20px !important;
    bottom: 20px !important;
}

.announcement_channel .sendbird-message-input--attach {
    display:none !important;
}

[dir="ltr"] .sendbird-openchannel-conversation-scroll .sendbird-openchannel-conversation-scroll__container__scroll-bottom-button {
    left: calc(100% - 60px) !important;
}

#banner_container{
    width:95%;
    overflow: hidden;
    white-space: nowrap;
    margin: auto !important;
    box-sizing: border-box;
  }
  
  #banner_text {
    width:'100%';
    display: inline-block;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation: marquee 60s linear infinite;
  }
  
  @keyframes marquee {
    from {
      transform: translateX(50%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  