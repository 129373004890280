.messages html,body {
    height:'100%' !important;
    overscroll-behavior: none !important;
}

.messages .sendbird-app__conversation-wrap {
    height: calc(100svh - 64px) !important;
    overscroll-behavior: none !important;
}

.messages .sendbird-channel-header .sendbird-ui-header__right {
    display: none;
}

.messages .sendbird-conversation {
    height: 100% !important;
}

.messages .sendbird-conversation__messages-padding {
    overflow: auto !important;
}

.messages .sendbird-channel-preview__content__upper__header__total-members {
    display: none;
}

.messages .sendbird-channel-settings-accordion__footer {
    display: none;
}

.messages .sendbird-channel-list {
    width: 100% !important;
}

.messages .sendbird-channel-preview {
    width: 100%!important;
}

/*320px*/
.messages .sendbird-channel-preview__content {
    flex-grow: 2 !important;
}


.messages .sendbird-message-content__middle {
    max-width:75% !important;
}

.messages .sendbird-message-content__right {
    position:absolute !important;
    right:0 !important;
    padding-left:15px !important;
}

.messages .sendbird-message-input--attach {
    display:none !important;
}

.messages .sendbird-message-content__left.outgoing {
    position:absolute !important;
    left:0 !important;
    padding-right:15px !important;
}

.messages .sendbird-message-content-menu {
    display:flex !important;
}

.messages .sendbird-message-content__left:not(.outgoing) {
    display:none !important;
}

.messages .sendbird-channel-preview__avatar {
    display:none !important;
}

.messages .sendbird-channel-preview .sendbird-channel-preview__action .sendbird-iconbutton {
    display:flex !important;
    margin-top:15px !important;
}

.messages .sendbird-channel-preview__content__upper {
    width: 100% !important;
}

.messages .sendbird-channel-preview__content__upper__header {
    flex-grow: 2 !important;
}

.messages .sendbird-channel-preview__content__upper__header__channel-name {
    flex-grow: 2 !important;
    max-width: 700px !important;
}

.messages .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__channel-name {
    max-width: 250px !important;
    word-wrap: break-word;
}